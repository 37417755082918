
// Backgrounds
$background-color--light: #fafafa;
$background-color--default: #F5F7FA;
$background-color--dark: #dcdcdc;

$background-color--header: #3c5574;
$background-color--body: #fff;
$background-color--header-new: $branding-slate;
$background-color--header-bottom-new: $branding-slate-light;
$background-color--product-launch-alert: #EEF1F6;
$background-color--archived-message: #F2A85B;
$background-color--menu-hover: #f2f2f2;
$background-color--header-white: #fff;

$background-color--billing-alert: #D2DAFA;

$background-color--new-primary: #4461d7;

$background-color--modal: #fff;
$background-color--w-popover: #fff;
$background-color--workload-grid-cell: #f5f7fa;

$background-color--selected: #4461d7;

:root {
    --background-color-light: #{$background-color--light};
    --background-color-default: #{$background-color--default};
    --background-color-dark: #{$background-color--dark};
    --background-color-header-white: #{$background-color--header-white};
    --background-color-body: #{$background-color--body};
    --background-color-modal: #{$background-color--modal};
    --background-color-w-popover: #{$background-color--w-popover};
    --background-color-workload-grid-cell: #{$background-color--workload-grid-cell};
}

.dark {
    --background-color-body: #1e2130;
    --background-color-light: #2b2e42;
    --background-color-default: #222536;
    --background-color-dark: #1c1e2d;
    --background-color-header-white: var(--background-color-body, #1e2130);
    --background-color-modal: #2c3144;
    --background-color-w-popover: var(--background-color-modal);
    --background-color-default: var(--background-color-body, #1e2130);
    --background-color-workload-grid-cell: #35394f;
}
