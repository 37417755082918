// *
// *
//  Tooltip/Popover
// *
//  Styles for the `v-tooltip` library.
//  While it seems like we're styling 2 seperate components (tooltip/popover)
//  They're both provided by the same library and are used together.
// *
// *
// *
// *

@import '~@tko/src/styles/variables/variables';
@import '../../mixins/arrows.mixin';

//  Variables
//  ----------
$gray-border: #838383;
$background--w-tooltip: #0b0f1f;
$text--w-tooltip: #c5cae0;
$arrow--w-tooltip: $background--w-tooltip;
$border--light--w-tooltip: rgba($gray-border, 0.1);
$border-radius--w-tooltip: 8px;
$spacing--w-tooltip: 8px;
$padding--w-tooltip__inner: 8px;
$font-size--w-tooltip__inner: 12px;

$background--w-popover: #fff;
$text--w-popover: #000;
$border--w-popover: #ddd;
$w-popover-arrow: $background--w-popover;
$arrow--w-popover: $background--w-popover;
$arrow-shadow--w-popover: rgba(0, 0, 0, 0.9);
$shadow--w-popover-shadow: rgba(0, 0, 0, 0.15);

//  Structure
//  ----------

%popover {
  display: block;
  z-index: 15001; // this is needed to beat the Modal's z-index which is 15000
}

.w-tooltip,
.w-popover {
  @extend %popover;

  &[aria-hidden='true'], // sass-lint:disable-line no-attribute-selectors
    &[x-out-of-boundaries] {
    // sass-lint:disable-line no-attribute-selectors
    visibility: hidden !important; // sass-lint:disable-line no-important
    opacity: 0 !important; // sass-lint:disable-line no-important
    transition: opacity 0.15s, visibility 0.15s !important; // sass-lint:disable-line no-important
    // important is needed to overwrite the inline styles added by the popover
  }

  &[aria-hidden='false'] {
    // sass-lint:disable-line no-attribute-selectors
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }

  &[x-placement^='top'] {
    // sass-lint:disable-line no-attribute-selectors
    margin-bottom: $spacing--w-tooltip;
  }

  &[x-placement^='bottom'] {
    // sass-lint:disable-line no-attribute-selectors
    margin-top: $spacing--w-tooltip;
  }

  &[x-placement^='right'] {
    // sass-lint:disable-line no-attribute-selectors
    margin-left: $spacing--w-tooltip;
  }

  &[x-placement^='left'] {
    // sass-lint:disable-line no-attribute-selectors
    margin-right: $spacing--w-tooltip;
  }

  &:focus {
    outline: none;
  }
}

//  Elements
//  ----------
// sass-lint:disable no-mergeable-selectors

.w-tooltip {
  &__inner {
    background-color: $background--w-tooltip;
    color: $text--w-tooltip;
    border-radius: $border-radius--w-tooltip;
    padding: $padding--w-tooltip__inner;
    font-size: $font-size--w-tooltip__inner;
  }

  &__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: $spacing--w-tooltip;
    border-color: $arrow--w-tooltip;
    z-index: 1;

    &:not(.w-popover__arrow) {
      [x-placement^='top'] & {
        // sass-lint:disable-line no-attribute-selectors
        @include arrow-top;
      }

      [x-placement^='bottom'] & {
        // sass-lint:disable-line no-attribute-selectors
        @include arrow-bottom;
      }

      [x-placement^='right'] & {
        // sass-lint:disable-line no-attribute-selectors
        @include arrow-left;
      }

      [x-placement^='left'] & {
        // sass-lint:disable-line no-attribute-selectors
        @include arrow-right;
      }
    }
  }
}

%popover-inner {
  background-color: var(--background-color-w-popover, #{$background--w-popover});
  color: $text--w-popover;
  border: 1px solid $border--w-popover;
  border-radius: $spacing--w-tooltip - 1px;
  box-shadow: 0 0 8px $shadow--w-popover-shadow;
}

.w-popover {
  &__inner {
    @extend %popover-inner;
    padding: 0;
    border-radius: 8px;
    display: inline-block;

    @at-root .dark & {
      color: var(--color-text-default, #fff);
    }
  }

  &__arrow {
    border-color: $arrow--w-popover;
    filter: drop-shadow(0 0 0 $arrow-shadow--w-popover);
  }

  // this is to ensure nested popover arrows don't inherit parent styles
  > .wrapper > &__arrow {
    // sass-lint:disable-line no-combinators
    [x-placement^='top'] & {
      // sass-lint:disable-line no-attribute-selectors no-combinators
      @include arrow-top;
    }

    [x-placement^='bottom'] & {
      // sass-lint:disable-line no-attribute-selectors no-combinators
      @include arrow-bottom;
    }

    [x-placement^='right'] & {
      // sass-lint:disable-line no-attribute-selectors no-combinators
      @include arrow-left;
    }

    [x-placement^='left'] & {
      // sass-lint:disable-line no-attribute-selectors no-combinators
      @include arrow-right;
    }
  }
}

//  Modifiers
//  ----------

.w-tooltip {
  max-width: 200px;
  overflow-wrap: break-word;

  &--light {
    @extend %popover;

    .w-tooltip__inner {
      background-color: $text--w-tooltip;
      color: map-get($color-list, 'gray-dark');
      // border: 1px solid $border--light--w-tooltip;
      font-size: $font-size--w-tooltip__inner;
      border-radius: $border-radius--w-tooltip;
      box-shadow: none;
    }

    .w-tooltip__arrow {
      display: none;
    }
  }

  .w-tooltip__arrow {
    display: none;
  }

  &--grey {
    @extend %popover;

    .w-tooltip__inner {
      background-color: $neutral-10;
      color: $slate-20;
      padding: 20px;
      width: 285px;
      margin-left: 20px;
      box-shadow: 0 0 8px $shadow--w-popover-shadow;
    }

    .w-tooltip__arrow {
      display: none;
    }
  }
}

.w-popover {
  &--slim {
    @extend %popover;

    .w-popover__inner {
      border-radius: 4px;
      box-shadow: none;
    }
  }
}

// allows v-popover to use block instead of inline-block
// used for example in v-popover's used in menu items
.v-popover--block > .trigger {
  // sass-lint:disable-line no-combinators
  display: block !important; // sass-lint:disable-line no-important
}
