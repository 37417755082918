// Borders
$border-color--light: #eceff3;
$border-color--lighter: #f2f2f2;
$border-color--extralight: $border-color--lighter;
$border-color--default: #EDF1F4;
$border-color--dark: darken(#dcdcdc, 5%);
$border-color--x-dark: #a9a9a9;
$border-color--highlight: #63a9f9;
$border-color--blackish: $color--heading;
$border-color--page-tabs: #f5f7fa;

$border-color--link-action: rgba(95, 110, 133, 0.2);
$border-color--link-action-hover: rgba(95, 110, 133, 1);

:root {
  --border-color-light: #{$border-color--light};
  --border-color-lighter: #{$border-color--lighter};
  --border-color-extralight: #{$border-color--extralight};
  --border-color-default: #{$border-color--default};
  --border-color-dark: #{$border-color--dark};
  --border-color-x-dark: #{$border-color--x-dark};
  --border-color-highlight: #{$border-color--highlight};
  --border-color-page-tabs: #{$border-color--page-tabs};

  --border-color-link-action: #{$border-color--link-action};
  --border-color-link-action-hover: #{$border-color--link-action-hover};
}

.dark {
  --border-color-light: #3a3e52;
  --border-color-lighter: #424659;
  --border-color-extralight: #424659;
  --border-color-default: #3e4255;
  --border-color-dark: #505468;
  --border-color-x-dark: #6a6f85;
  --border-color-highlight: #4c83c8;
  --border-color-page-tabs: #3a3e52;

  --border-color-link-action: rgba(160, 175, 205, 0.2);
  --border-color-link-action-hover: rgba(160, 175, 205, 1);
}
