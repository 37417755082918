//Branding
$white: #fff;
$color--brand-blue: #3b93f7;
$color--brand-blue-dark: darken($color--brand-blue, 20%); //??
$color--brand-red: #ff3454;
$color--brand-green: #65d656;
$color--brand-green-dark: darken($color--brand-green, 20%);
$color--brand-navy: #3b4b69;

$color--success: #34c48b;
$color--link: $color--brand-blue;
$color--link-hover: #63a9f9;
$color--error: #ff6562;
$color--info: #f2825b;
$color--warning: #ffc486;
$color--danger: $color--error;
$color--disabled: #cccccc;

$color--today: #6CB3FF;
$color--late: #ff6461;
$color--upcoming: #febe6f;

$branding-slate: #1D1C39;
$branding-slate-light: #373753;

// branding extended pallete

// pink
$pink-20: #f8cae8;
$pink-40: #ff97da;
$pink-60: #ff61c7;
$tw-pink: #ff22b1;
$pink-100: #88145f;
// slate
$slate-20: #727491;
$slate-40: #494e6a;
$slate-60: #373753;
$tw-slate: #1d1c39;
$slate-100: #0b0e1f;
// purple
$purple-20: #d5c8fb;
$purple-40: #aa8df8;
$purple: #895ef7;
$purple-80: #6438e0;
$purple-100: #443276;
// indigo
$indigo-20: #8F9FE7;
$indigo-30: #4461d7;
$indigo-40: #3C55BD;
$indigo-50: #364DAA;
$indigo: #6a84ed;
$indigo-80: #4461d7;
$indigo-100: #323e9c;
// indigo transparency
$indigo-20--transparency-80: rgba(143, 159, 231, 0.8);
$indigo-30--transparency-04: rgba(68, 97, 215, 0.04);
$indigo-30--transparency-12: rgba(68, 97, 215, 0.12);
$indigo-30--transparency-20: rgba(68, 97, 215, 0.2);
$indigo-30--transparency-24: rgba(68, 97, 215, 0.24);
// blue
$blue-20: #a3e0fc;
$blue-40: #63c0f9;
$blue: #4da2f8;
$blue-80: #3d8aed;
$blue-100: #2b59ab;
// teal
$teal-20: #b9f4f3;
$teal-40: #82ebf1;
$teal: #1fcbdc;
$teal-80: #0390a9;
$teal-100: #005c6f;
// green
$green-20: #baf8dd;
$green-40: #85f1b8;
$green: #79e3aa;
$green-80: #59c894;
$green-100: #518d7b;
// yellow
$yellow-20: #ffeab6;
$yellow-40: #ffd56a;
$yellow: #ffc63c;
$yellow-80: #feb51f;
$yellow-100: #ce8d4f;
// orange
$orange-20: #ffcfb7;
$orange-40: #ff9c73;
$orange: #ff7641;
$orange-80: #f45721;
$orange-100: #ac241b;
// red
$red-20: #fba7bf;
$red-30: #E12D42;
$red-40: #CB283B;
$red-50: #B42435;
$red: #f03063;
$red-80: #c00839;
$red-100: #8d0217;
// brown
$brown-20: #e9b78c;
$brown-40: #d47a3c;
$brown: #ca6419;
$brown-80: #b74d00;
$brown-100: #703713;
// neutral
$neutral-10: #f8fafc;
$neutral-20: #f5f7fa;
$neutral-30: #eef1f6;
$neutral-40: #e7ebf1;
$neutral-50: #e1e6ee;
$neutral-60: #d6dee7;
$neutral-70: #ccd5e2;
$neutral-80: #c5cada;
$neutral-90: #b3b9ce;
$neutral-100: #a7acc5;

:root {
  --neutral-10: #{$neutral-10};
  --neutral-20: #{$neutral-20};
  --neutral-30: #{$neutral-30};
  --neutral-40: #{$neutral-40};
  --neutral-50: #{$neutral-50};
  --neutral-60: #{$neutral-60};
  --neutral-70: #{$neutral-70};
  --neutral-80: #{$neutral-80};
  --neutral-90: #{$neutral-90};
  --neutral-100: #{$neutral-100};
}

.dark {
  --neutral-10: #2e3249;
  --neutral-20: #35394f;
  --neutral-30: #3d4258;
  --neutral-40: #464d65;
  --neutral-50: #51587b;
  --neutral-60: #5d648f;
  --neutral-70: #6b729d;
  --neutral-80: #7e84ae;
  --neutral-90: #939bc0;
  --neutral-100: #a7b0d3;
}

:export {
    // pink
    pink20: $pink-20;
    pink40: $pink-40;
    pink60: $pink-60;
    twPink: $tw-pink;
    pink100: $pink-100;
    // slate
    slate20: $slate-20;
    slate40: $slate-40;
    slate60: $slate-60;
    twSlate: $tw-slate;
    slate100: $slate-100;
    // purple
    purple20: $purple-20;
    purple40: $purple-40;
    purple: $purple;
    purple80: $purple-80;
    purple100: $purple-100;
    // indigo
    indigo20: $indigo-20;
    indigo40: $indigo-40;
    indigo: $indigo;
    indigo80: $indigo-80;
    indigo100: $indigo-100;
    // blue
    blue20: $blue-20;
    blue40: $blue-40;
    blue: $blue;
    blue80: $blue-80;
    blue100: $blue-100;
    // teal
    teal20: $teal-20;
    teal40: $teal-40;
    teal: $teal;
    teal80: $teal-80;
    teal100: $teal-100;
    // green
    green20: $green-20;
    green40: $green-40;
    green: $green;
    green80: $green-80;
    green100: $green-100;
    // yellow
    yellow20: $yellow-20;
    yellow40: $yellow-40;
    yellow: $yellow;
    yellow80: $yellow-80;
    yellow100: $yellow-100;
    // orange
    orange20: $orange-20;
    orange40: $orange-40;
    orange: $orange;
    orange80: $orange-80;
    orange100: $orange-100;
    // red
    red20: $red-20;
    red30: $red-30;
    red40: $red-40;
    red: $red;
    red80: $red-80;
    red100: $red-100;
    // brown
    brown20: $brown-20;
    brown40: $brown-40;
    brown: $brown;
    brown80: $brown-80;
    brown100: $brown-100;
    // neutral
    neutral10: $neutral-10;
    neutral20: $neutral-20;
    neutral30: $neutral-30;
    neutral40: $neutral-40;
    neutral50: $neutral-50;
    neutral60: $neutral-60;
    neutral70: $neutral-70;
    neutral80: $neutral-80;
    neutral90: $neutral-90;
    neutral100: $neutral-100;
}
